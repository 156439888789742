
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { AssetInterface } from "@/interfaces/AssetInterface";
import { ValidationFlags } from "vee-validate/dist/types/types";
import { AxiosError } from "axios";
import { handleError } from "@/api/responseHandler";
import CategorySelectValidated from "@/components/categories/CategorySelectValidated.vue";
import PropertyAutocomplete from "@/components/properties/form/PropertyAutocomplete.vue";

const assets = namespace("assets");
@Component({
  components: { PropertyAutocomplete, CategorySelectValidated },
})
export default class AssetForm extends Vue {
  @assets.Action("createAsset")
  private createAsset!: ActionMethod;

  @assets.Action("updateAsset")
  private updateAsset!: ActionMethod;

  @assets.State("loading")
  private loading?: boolean;

  @Prop({ required: true })
  public asset?: AssetInterface;

  async save() {
    if (this.asset) {
      const asset = this.asset;
      try {
        !asset.id
          ? await this.createAsset(this.asset)
          : await this.updateAsset(this.asset);

        this.$emit("saved", this.asset);
        this.$toast.success("Asset saved successfully");

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
