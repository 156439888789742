import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/bootstrap-vue";
import "./plugins/vue-toastification";
import "./plugins/logger";
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

Vue.config.productionTip = false;

// Configure vee-validate
Object.keys(rules).forEach((rule: string) => {
  extend(rule, rules[rule as keyof typeof rules]);
});
localize("en", en);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
