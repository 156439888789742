
import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { sitePlaceholder, SitesState } from "@/store/sites";
import { SiteInterface } from "@/interfaces/SiteInterface";
import SiteModal from "@/components/sites/modal/SiteModal.vue";
import DeleteSiteConfirmation from "@/components/sites/modal/DeleteSiteConfirmation.vue";
import { ApiPaginationInterface } from "@/interfaces/ApiInterface";
import { BTable, BvTableCtxObject } from "bootstrap-vue";
import { RootState } from "@/store";
import { handleError } from "@/api/responseHandler";

const sites = namespace("sites");
@Component({
  components: { DeleteSiteConfirmation, SiteModal },
})
export default class SitesTable extends Vue {
  @sites.Action("loadSites")
  private loadSites!: ActionContext<SitesState, RootState>;

  @sites.State("sites")
  private sites?: SiteInterface[];

  @sites.State((state) => state.meta.pagination)
  private pagination?: ApiPaginationInterface;

  @sites.State("loading")
  private loading?: boolean;

  private sortBy = "branch_modified";
  private sortDesc = true;

  private fields = [
    {
      key: "branch_id",
      label: "ID",
      sortable: true,
    },
    {
      key: "branch_name",
      label: "Name",
      sortable: true,
    },
    {
      key: "site_address",
      label: "Address",
      sortable: false,
      formatter: (
        value?: string | number,
        key?: string | number,
        item: SiteInterface = {}
      ) => {
        return [
          item.branch_address_1,
          item.branch_address_2,
          item.branch_address_county,
          item.branch_address_postcode,
        ]
          .filter(Boolean)
          .join(", ");
      },
    },
    {
      key: "branch_telephone",
      label: "Telephone",
      sortable: false,
    },
    {
      key: "branch_created",
      label: "Created Date",
      sortable: true,
    },
    {
      key: "branch_modified",
      label: "Last Updated",
      sortable: true,
    },
    { key: "actions", label: "Actions", sortable: false },
  ];

  private siteModel?: SiteInterface = sitePlaceholder;

  async provider(context: BvTableCtxObject) {
    try {
      await this.loadSites({
        limit: context.perPage,
        page: context.currentPage,
        orderBy: context.sortBy,
        sortedBy: context.sortDesc ? "desc" : "asc",
      });
    } catch (error) {
      handleError(error, {});
    }

    return this.sites;
  }

  displayDate(date: string, includeTimestamp = false) {
    //@todo create a vue helper for this
    return moment(date).format(
      "DD/MM/YYYY" + (includeTimestamp ? " HH:mm:ss" : "")
    );
  }

  editSite(site: SiteInterface) {
    this.siteModel = Object.assign({}, site);
    this.$bvModal.show("site-modal");
  }

  createSite() {
    this.siteModel = Object.assign({}, sitePlaceholder);
    this.$bvModal.show("site-modal");
  }

  deleteSite(site: SiteInterface) {
    this.siteModel = Object.assign({}, site);
    this.$bvModal.show("delete-site-modal");
  }

  refresh() {
    if (this.$refs.sitesTable) {
      const table: BTable = this.$refs.sitesTable as BTable;
      table.refresh();
    } else {
      this.$log.error("Unable to find sites table to refresh");
    }
  }

  resetSite() {
    this.siteModel = Object.assign({}, sitePlaceholder);
  }
}
