import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import userModule, { UserState } from "@/store/user";
import propertiesModule, { PropertiesState } from "@/store/properties";
import assetsModule, {
  AssetsState,
  getInitialAssetsState,
} from "@/store/assets";
import sitesModule, { SitesState } from "@/store/sites";
import categoriesModule, { CategoriesState } from "@/store/categories";
import _ from "lodash";
import contactsModule, { ContactsState } from "@/store/contacts";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    assets: _.merge(getInitialAssetsState(), {
      filters: state.assets?.filters,
    }),
    properties: _.merge(getInitialAssetsState(), {
      filters: state.properties?.filters,
    }),
    contacts: _.merge(getInitialAssetsState(), {
      filters: state.contacts?.filters,
    }),
  }),
});

export interface RootState {
  loading: boolean;
  user?: UserState;
  assets?: AssetsState;
  properties?: PropertiesState;
  sites?: SitesState;
  categories?: CategoriesState;
  contacts?: ContactsState;
}

export default new Vuex.Store({
  state: (): RootState => ({
    loading: false,
  }),

  modules: {
    user: userModule,
    assets: assetsModule,
    properties: propertiesModule,
    sites: sitesModule,
    categories: categoriesModule,
    contacts: contactsModule,
  },

  plugins: [vuexLocal.plugin],
});
