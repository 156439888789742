
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { SelectOption } from "@/api/defaults";
import { namespace } from "vuex-class";
import { SiteInterface } from "@/interfaces/SiteInterface";
import { ValidationRule } from "vee-validate/dist/types/types";

const sites = namespace("sites");

@Component({})
export default class SiteSelectValidated extends Vue {
  @Prop({
    required: false,
    default: undefined,
    type: Number,
  })
  private selected!: number | undefined; //@todo investigate switching to using watchers so the parent can use v-model instead of this + listener

  @Prop({ required: true, type: String })
  private readonly id!: string;

  @Prop({ required: false, type: String, default: "Site" })
  private readonly label!: string;

  @Prop({ required: false, default: "All Sites" })
  private defaultOption!: string | false;

  @Prop({ required: false, default: "", type: [Object, String] })
  private rules!: string | ValidationRule[]; //@todo figure out why valid state is true even on initial load of component, rather than null

  @sites.Action("getSites")
  private getSites!: ActionMethod;

  @sites.State("loading")
  private loading?: boolean;

  private sites: SelectOption[] = [];

  private error = false;

  async created() {
    try {
      const sites = await this.getSites();
      if (this.defaultOption) {
        this.sites.push({
          value: undefined,
          text: this.defaultOption,
          disabled: true,
        });
      }
      sites.forEach((site: SiteInterface) => {
        this.sites.push({
          value: site.branch_id,
          text: [
            site.branch_name,
            site.branch_address_1,
            site.branch_address_town,
            site.branch_address_postcode,
          ]
            .filter(Boolean)
            .join(", "),
        });
      });
    } catch (error: Error | unknown) {
      this.error = true;
      this.selected = undefined;
      this.sites.push({
        value: undefined,
        text: "Error loading site data",
      });
    }
  }
}
