import axios, { AxiosError } from "axios";
import Vue from "vue";
import {
  error404,
  error500,
  errorAxios,
  errorDefault,
  ErrorResponseMessage,
  errorUnknown,
} from "@/api/errors";

export interface ErrorResponseMessageMap {
  [code: number]: ErrorResponseMessage;
}

const defaultErrorMap: ErrorResponseMessageMap = {
  404: error404,
  500: error500,
};

const getErrorMessage = (
  error: AxiosError | Error | unknown,
  errorMap: ErrorResponseMessageMap
): ErrorResponseMessage => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.status) {
      const message = errorMap[error.response.status];
      if (message) {
        return message;
      }
    }
    const message = error.message ?? "No error message";
    return errorAxios(message);
  }
  if (error instanceof Error && error.message) {
    return errorUnknown(error.message);
  }
  return errorDefault;
};

export const showError = (errorMessage: ErrorResponseMessage) => {
  Vue.$log.debug("Error debug:", errorMessage.debug);
  return Vue.prototype.$toast.error(errorMessage.message);
};

export const handleError = (
  error: AxiosError | Error | unknown,
  errorMap?: ErrorResponseMessageMap
) => {
  showError(getErrorMessage(error, { ...defaultErrorMap, ...errorMap }));
};
