
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const user = namespace("user");

@Component
export default class HomeView extends Vue {
  @user.Getter("isLoggedIn") isLoggedIn!: boolean;
}
