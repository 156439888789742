
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { handleError } from "@/api/responseHandler";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { RootState } from "@/store";
import { AssetsState } from "@/store/assets";
import { AssetInterface } from "@/interfaces/AssetInterface";

const assets = namespace("assets");

@Component
export default class DeletePropertyConfirmation extends Vue {
  @Prop({ required: true })
  private asset?: AssetInterface;

  @assets.Action("deleteAsset")
  private commitDelete!: ActionContext<AssetsState, RootState>;

  async deleteAsset() {
    if (this.asset) {
      const asset = this.asset;
      try {
        await this.commitDelete(asset);
        this.$toast.success("Asset deleted successfully");
        this.$emit("deleted", asset);

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }
}
