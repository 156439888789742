
import { Component } from "vue-property-decorator";
import Vue from "vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import ContactFilters from "@/components/contacts/form/ContactFilters.vue";
import ContactsTable from "@/components/contacts/ContactsTable.vue";

@Component({
  components: { ContactsTable, ContactFilters, Sidebar },
})
export default class ContactsView extends Vue {}
