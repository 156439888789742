import Vue from "vue";
import VueRouter, {
  NavigationGuardNext,
  Route,
  RouteConfig,
  RouterOptions,
} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AssetsView from "@/views/assets/AssetsView.vue";
import PropertiesView from "@/views/properties/PropertiesView.vue";
import store from "@/store";
import SitesView from "@/views/sites/SitesView.vue";
import ContactsView from "@/views/contacts/ContactsView.vue";

Vue.use(VueRouter);

const authGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  const isLoggedIn = store.getters["user/isLoggedIn"];
  if (!isLoggedIn) {
    next({ name: "home" });
  } else {
    next();
  }
};

function parseQuery(route: Route): void {
  for (const key in route.query) {
    const value = route.query[key];
    if (typeof value === "string" && !Number.isNaN(Number(value))) {
      route.query[key] = Number(route.query[key]) as never;
    }
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/assets",
    name: "assets",
    meta: { title: "Assets" },
    component: AssetsView,
    children: [
      {
        path: "new",
        name: "create_asset",
      },
      {
        path: "edit/:id",
        name: "edit_asset",
      },
    ],
  },
  {
    path: "/properties",
    name: "properties",
    meta: { title: "Properties" },
    component: PropertiesView,
    children: [
      {
        path: "new",
        name: "create_property",
      },
      {
        path: "edit/:id",
        name: "edit_property",
      },
    ],
  },
  {
    path: "/sites",
    name: "sites",
    meta: { title: "Sites" },
    component: SitesView,
  },
  {
    path: "/contacts",
    name: "contacts",
    meta: { title: "Contacts" },
    component: ContactsView,
    children: [
      {
        path: "new",
        name: "create_contact",
      },
      {
        path: "edit/:id",
        name: "edit_contact",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
} as RouterOptions);

router.beforeEach((to, from, next) => {
  if (to.name !== "home") {
    authGuard(to, from, next);
  }

  parseQuery(to);
  next();
});

router.afterEach((to) => {
  const siteName = process.env.VUE_APP_SITE_NAME || "Asset Hive";
  document.title = to.meta?.title ? `${siteName} | ${to.meta.title}` : siteName;
});

export default router;
