
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PropertiesState } from "@/store/properties";
import { PropertyInterface } from "@/interfaces/PropertyInterface";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { handleError } from "@/api/responseHandler";
import { AxiosError } from "axios";
import { RootState } from "@/store";

const properties = namespace("properties");

@Component
export default class DeletePropertyConfirmation extends Vue {
  @Prop({ required: true })
  private property?: PropertyInterface;

  @properties.Action("deleteProperty")
  private commitDelete!: ActionContext<PropertiesState, RootState>;

  async deleteProperty() {
    if (this.property) {
      const property = this.property;
      try {
        await this.commitDelete(property);
        this.$toast.success("Property deleted successfully");
        this.$emit("deleted", property);

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }
}
