
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  AssetInterface,
  AssetMediaInterface,
} from "@/interfaces/AssetInterface";
import { namespace } from "vuex-class";
import { handleError } from "@/api/responseHandler";
import { ActionMethod } from "vuex";
import { ValidationFlags } from "vee-validate/dist/types/types";

const assets = namespace("assets");
@Component({ components: {} })
export default class AssetMedia extends Vue {
  @Prop({ required: true })
  private asset!: AssetInterface;

  @assets.State("media")
  private media?: AssetMediaInterface[];

  @assets.Action("loadMedia")
  private loadMedia!: ActionMethod;

  @assets.Action("uploadMedia")
  private uploadMedia!: ActionMethod;

  @assets.Action("deleteAssetMedia")
  private deleteAssetMedia!: ActionMethod;

  private type: null | string = null;

  private name: null | string = null;

  private file: null | string = null;

  private loading = false;

  private mediaTypes = [
    { value: null, text: "-- Please select a media type --" },
    { value: "asset_image", text: "Asset Image" },
    { value: "invoice", text: "Purchase Invoice" },
    { value: "warranty", text: "Warranty Document" },
  ];

  private sortBy = "name";

  private sortDesc = true;

  private mediaFields = [
    {
      key: "name",
      label: "File",
      sortable: true,
    },
    {
      key: "type",
      label: "Media Type",
      sortable: true,
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false,
    },
  ];

  async created() {
    if (!this.asset.id) {
      return [];
    }

    try {
      this.loading = true;
      await this.loadMedia(this.asset);
    } catch (error) {
      handleError(error, {});
    } finally {
      this.loading = false;
    }
  }

  async save() {
    try {
      await this.uploadMedia({
        asset: this.asset,
        media: {
          type: this.type,
          name: this.name,
          file: this.file,
        } as AssetMediaInterface,
      });

      this.name = "";
      this.type = null;
      this.file = "";
      this.$nextTick(() => this.$refs?.observer?.reset());
    } catch (error: unknown) {
      handleError(error);
    }
  }

  deleteMedia(media: AssetMediaInterface) {
    try {
      this.deleteAssetMedia(media);
    } catch (error: unknown) {
      handleError(error);
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
