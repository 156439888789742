import Vue from "vue";
import { UserInterface, UserLogin } from "@/interfaces/UserInterface";
import { Module } from "vuex";
import router from "@/router";
import { RootState } from "@/store/index";
import client from "@/api/client";
import { AxiosResponse } from "axios";

export interface UserState {
  user: UserInterface | null;
  loading: boolean;
}

const userModule: Module<UserState, RootState> = {
  namespaced: true,

  state: (): UserState => ({
    user: null,
    loading: false,
  }),

  getters: {
    isLoggedIn(state: UserState) {
      return !(!state.user || !state.user.token);
    },
  },

  mutations: {
    setUser: (state: UserState, user: UserInterface) => {
      state.user = user;
    },

    setLoading: (state: UserState, loading: boolean) => {
      state.loading = loading;
    },
  },

  actions: {
    async login({ commit }, user: UserLogin): Promise<AxiosResponse | unknown> {
      try {
        commit("setLoading", true);
        const response = await client.post("login", {
          username: user.email,
          password: user.password,
        });

        commit("setUser", {
          email: user.email,
          token: response.data.data.access_token,
        });

        return response;
      } catch (error) {
        commit("setUser", null);

        throw error;
      } finally {
        commit("setLoading", false);
      }
    },

    logout({ commit }) {
      commit("setUser", null);
      commit("sites/reset", null, { root: true });
      commit("properties/reset", null, { root: true });
      commit("assets/reset", null, { root: true });
      commit("categories/reset", null, { root: true });

      if (router.currentRoute.name !== "home") {
        router.push({ name: "home" });
      }
      Vue.prototype.$toast.success("You are now logged out");
    },
  },
};

export default userModule;
