
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { propertyPlaceholder } from "@/store/properties";
import {
  PropertyApiResponseInterface,
  PropertyInterface,
} from "@/interfaces/PropertyInterface";
import PropertyModal from "@/components/properties/modal/PropertyModal.vue";
import DeletePropertyConfirmation from "@/components/properties/modal/DeletePropertyConfirmation.vue";
import _ from "lodash";
import {
  ApiPaginationInterface,
  ApiQueryInterface,
} from "@/interfaces/ApiInterface";
import { BTable, BvTableCtxObject } from "bootstrap-vue";
import { handleError } from "@/api/responseHandler";
import { Route } from "vue-router";
import client from "@/api/client";
import { AxiosResponse } from "axios";
import { pushRoute } from "@/helpers/utilMethods";

const properties = namespace("properties");
@Component({
  components: { DeletePropertyConfirmation, PropertyModal },
})
export default class PropertiesTable extends Vue {
  @properties.Action("loadProperties")
  private loadProperties!: ActionMethod;

  @properties.State("properties")
  private properties?: PropertyInterface[];

  @properties.State((state) => state.meta.pagination)
  private pagination?: ApiPaginationInterface;

  @properties.State((state) => state.filters)
  private filters?: ApiQueryInterface;

  @properties.State("loading")
  private loading?: boolean;

  private sortBy = "property_modified";
  private sortDesc = true;

  private fields = [
    {
      key: "property_id",
      label: "ID",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "branch.data.branch_name",
      label: "Branch",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "property_address",
      label: "Address",
      sortable: false,
      formatter: (
        value?: string | number,
        key?: string | number,
        item?: PropertyInterface
      ) => {
        return !item
          ? ""
          : [
              item.property_address_name,
              item.property_address_1,
              item.property_address_2,
              item.property_address_county,
              item.property_address_postcode,
            ]
              .filter(Boolean)
              .join(", ");
      },
      toggleable: true,
      visible: true,
    },
    {
      key: "property_type",
      label: "Type",
      sortable: true,
      formatter: (value: string) => {
        return _.startCase(value);
      },
      toggleable: true,
      visible: true,
    },
    {
      key: "property_status",
      label: "Status",
      toggleable: true,
      visible: true,
    },
    {
      key: "property_created",
      label: "Created Date",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "property_modified",
      label: "Last Updated",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false,
      visible: true,
      toggleable: false,
    },
  ];

  private propertyModel?: PropertyInterface = propertyPlaceholder;

  @Watch("$route", { deep: true, immediate: true })
  async handleNavigation(route: Route) {
    if (route.name === "create_property") {
      this.createProperty();
    }

    if (route.name === "edit_property") {
      if (!route.params.id) {
        this.$toast.error("No property ID present in the URL");
        return false;
      }

      const propertyId: string = route.params?.id;
      let property: PropertyInterface | null = null;
      await client
        .get(`/properties/${propertyId}`)
        .then((response: AxiosResponse) => {
          if (response.data) {
            const responseData: PropertyApiResponseInterface = response.data;
            property = responseData.data;
          }
        });

      if (!property) {
        this.$toast.error(`Unable to find property ID ${propertyId}`);
        return false;
      }

      this.editProperty(property);
    }
  }

  get visibleFields() {
    return this.fields.filter((field) => field.visible);
  }

  get toggleableFields() {
    return this.fields.filter((field) => field.toggleable);
  }

  //@todo fix duplicate network request on paginate / filter
  async provider(context: BvTableCtxObject) {
    try {
      await this.loadProperties({
        limit: context.perPage,
        page: context.currentPage,
        orderBy: context.sortBy,
        sortedBy: context.sortDesc ? "desc" : "asc",
      });
    } catch (error) {
      handleError(error, {});
    }

    return this.properties;
  }

  displayDate(date: string, includeTimestamp = false) {
    //@todo create a vue helper for this
    return moment(date).format(
      "DD/MM/YYYY" + (includeTimestamp ? " HH:mm:ss" : "")
    );
  }

  getPropertyStatusVariant(status: string) {
    switch (status) {
      case "active":
        return "success";

      case "draft":
      default:
        return "";
    }
  }

  editProperty(property: PropertyInterface) {
    this.propertyModel = Object.assign({}, property);
    this.$bvModal.show("property-modal");
  }

  createProperty() {
    this.propertyModel = Object.assign({}, propertyPlaceholder);
    this.$bvModal.show("property-modal");
  }

  deleteProperty(property: PropertyInterface) {
    this.propertyModel = Object.assign({}, property);
    this.$bvModal.show("delete-property-modal");
  }

  refresh() {
    if (this.$refs.propertiesTable) {
      const table: BTable = this.$refs.propertiesTable as BTable;
      table.refresh();
    } else {
      this.$log.error("Unable to find properties table to refresh");
    }
  }

  reset() {
    pushRoute(this.$router, { name: "properties" });
    this.resetProperty();
  }

  resetProperty() {
    this.propertyModel = Object.assign({}, propertyPlaceholder);
  }
}
