
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { MutationMethod } from "vuex";
import { namespace } from "vuex-class";
import { PropertyFilterInterface } from "@/interfaces/PropertyInterface";
import { convertFilterQueryToFilters, Filter } from "@/api/defaults";
import CategorySelect from "@/components/categories/CategorySelect.vue";
import SiteSelect from "@/components/sites/form/SiteSelect.vue";
import PropertyAutocomplete from "@/components/properties/form/PropertyAutocomplete.vue";
import { pushRoute } from "@/helpers/utilMethods";
import _ from "lodash";

const properties = namespace("properties");

const getDefaultFilters = (): PropertyFilterInterface => ({
  search: "",
  property_branch_id: undefined,
});

@Component({
  components: { PropertyAutocomplete, SiteSelect, CategorySelect },
})
export default class PropertyFilters extends Vue {
  @properties.State("loading")
  private loading?: boolean;

  @properties.Mutation("setFilters")
  private setFilters!: MutationMethod;

  @properties.State("filters")
  private initialFilters!: Filter[];

  private filters: PropertyFilterInterface = getDefaultFilters();

  created() {
    this.loadInitialFilters();
  }

  private filter(filters: PropertyFilterInterface) {
    this.setFilters(filters);
    this.mapFiltersToUrl(filters);
  }

  private clearFilters() {
    this.filters = getDefaultFilters();
    this.setFilters({});
    this.mapFiltersToUrl({});
  }

  private mapFiltersToUrl(filters: PropertyFilterInterface) {
    pushRoute(this.$router, {
      name: this.$route.name ?? "",
      query: _.pickBy(filters, _.identity) as never,
    });
  }

  private loadInitialFilters() {
    let filters = {};
    if (this.initialFilters) {
      const propertyFilters = convertFilterQueryToFilters(this.initialFilters);

      filters = _.merge(getDefaultFilters(), propertyFilters);
      this.mapFiltersToUrl(propertyFilters);
    }

    if (this.$route.query.length) {
      filters = _.merge(getDefaultFilters(), this.$route.query);
    }

    this.filters = filters;
    this.setFilters(filters);
  }
}
