
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { UserLogin } from "@/interfaces/UserInterface";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { ValidationFlags } from "vee-validate/dist/types/types";
import router from "@/router";
import { handleError } from "@/api/responseHandler";
import { AxiosError } from "axios";

const user = namespace("user");

@Component
export default class LoginForm extends Vue {
  @user.Action("login")
  private commitLogin!: ActionMethod;

  @user.State("loading")
  private loading?: boolean;

  private form: UserLogin = {
    email: undefined,
    password: undefined,
  };

  async login() {
    try {
      await this.commitLogin(this.form);
      await router.push({ name: "assets" });
      this.$toast.success("You are now logged in");
      this.$emit("user-logged-in");

      return true;
    } catch (error: AxiosError | unknown) {
      handleError(error, {
        400: {
          message:
            "Whoops! It doesn't look like we have a user with those credentials",
          type: "error",
        },
      });

      return false;
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
