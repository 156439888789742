
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { PropertyInterface } from "@/interfaces/PropertyInterface";
import { ValidationFlags } from "vee-validate/dist/types/types";
import { AxiosError } from "axios";
import { handleError } from "@/api/responseHandler";
import SiteSelectValidated from "@/components/sites/form/SiteSelectValidated.vue";

const properties = namespace("properties");
@Component({
  components: { SiteSelectValidated },
})
export default class PropertyForm extends Vue {
  @properties.Action("createProperty")
  private createProperty!: ActionMethod;

  @properties.Action("updateProperty")
  private updateProperty!: ActionMethod;

  @properties.State("loading")
  private loading?: boolean;

  @Prop({ required: true })
  public property?: PropertyInterface;

  public regions = [
    { value: "england", text: "England" },
    { value: "scotland", text: "Scotland" },
    { value: "wales", text: "Wales" },
    { value: "northern_ireland", text: "Northern Ireland" },
  ];

  async save() {
    if (this.property) {
      const property = this.property;
      try {
        !property.property_id
          ? await this.createProperty(this.property)
          : await this.updateProperty(this.property);

        this.$emit("saved", this.property);
        this.$toast.success("Property saved successfully");

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
