
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SitesState } from "@/store/sites";
import { SiteInterface } from "@/interfaces/SiteInterface";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { handleError } from "@/api/responseHandler";
import { AxiosError } from "axios";
import { RootState } from "@/store";

const sites = namespace("sites");

@Component
export default class DeleteSiteConfirmation extends Vue {
  @Prop({ required: true })
  private site?: SiteInterface;

  @sites.Action("deleteSite")
  private commitDelete!: ActionContext<SitesState, RootState>;

  async deleteSite() {
    if (this.site) {
      const site = this.site;
      try {
        await this.commitDelete(site);
        this.$toast.success("Site deleted successfully");
        this.$emit("deleted", site);

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }
}
