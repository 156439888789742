export interface ErrorResponseMessage {
  message: string;
  debug: string;
  type: "error" | "warning" | "info" | "success";
}

export const errorDefault: ErrorResponseMessage = {
  message: "Whoops! An unknown error occurred - please try again",
  debug: "Unknown error",
  type: "error",
};

export const error404: ErrorResponseMessage = {
  message: "Whoops! that doesn't seem to exist",
  debug: "404 not found",
  type: "error",
};

export const error500: ErrorResponseMessage = {
  message: "Whoops! the server had a hiccup - please try again",
  debug: "500 intenral server error",
  type: "error",
};

export const errorAxios = (message: string): ErrorResponseMessage => ({
  message: "Whoops! An unknown request error occurred",
  debug: "Axios error with no status : " + message,
  type: "error",
});

export const errorUnknown = (message: string): ErrorResponseMessage => ({
  message: "Whoops! An unknown error occurred",
  debug: "Unknown error : " + message,
  type: "error",
});
