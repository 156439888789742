import { Module } from "vuex";
import {
  AssetFilterInterface,
  AssetInterface,
  AssetMediaInterface,
  AssetsApiResponseInterface,
} from "@/interfaces/AssetInterface";
import { ApiMetaInterface } from "@/interfaces/ApiInterface";
import client from "@/api/client";
import { RootState } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { convertFiltersToQuery, Filter, metaPlaceholder } from "@/api/defaults";
import _ from "lodash";

const defaultApiFields = [
  "id",
  "property_id",
  "asset_category_id",
  "category",
  "status",
  "reference",
  "ean",
  "description",
  "brand",
  "model",
  "supplier",
  "purchase_price",
  "purchase_date",
  "invoice_number",
  "under_warranty",
  "warranty_expiry_date",
  "servicing_reminder",
  "service_period",
  "maintenance_category",
  "first_service_date",
  "created_at",
  "updated_at",
  "pagination",
];

const defaultAssetsApiParams = {
  include: "category,property",
  filter: defaultApiFields.join(";"),
  limit: 10,
};

export const assetPlaceholder: AssetInterface = {
  property_id: undefined,
  status: "Active",
  reference: "",
  description: "",
  brand: "",
  model: "",
  supplier: "",
  warranty_expiry_date: "",
  servicing_reminder: false,
  maintenance_category: "Internal Maintenance",
};

export interface AssetsState {
  assets: AssetInterface[] | null;
  filters: Filter[];
  media: AssetMediaInterface[];
  meta: ApiMetaInterface | null;
  loading: boolean;
}

export const getInitialAssetsState = () => ({
  assets: [],
  filters: [],
  media: [],
  loading: false,
  meta: metaPlaceholder,
});

const assetsModule: Module<AssetsState, RootState> = {
  namespaced: true,

  state: (): AssetsState => getInitialAssetsState(),

  mutations: {
    setAssets(state: AssetsState, assets: AssetInterface[]) {
      state.assets = assets;
    },
    setFilters(state: AssetsState, filters: AssetFilterInterface) {
      state.filters = [];
      if (filters.search) {
        state.filters.push({ search: filters.search });
      }

      if (filters.branch_id) {
        state.filters.push({
          field: "site_id",
          search: filters.branch_id,
          condition: "=",
        });
      }

      if (filters.property_id) {
        state.filters.push({
          field: "property_id",
          search: filters.property_id,
          condition: "=",
        });
      }

      if (filters.category_id) {
        state.filters.push({
          field: "category_id",
          search: filters.category_id,
          condition: "=",
        });
      }

      if (filters.make) {
        state.filters.push({
          field: "make",
          search: filters.make,
          condition: "like",
        });
      }

      if (filters.model) {
        state.filters.push({
          field: "model",
          search: filters.model,
          condition: "like",
        });
      }
    },
    setMedia(state: AssetsState, media: AssetMediaInterface[]) {
      state.media = media;
    },
    setMeta(state: AssetsState, meta: ApiMetaInterface) {
      state.meta = meta;
    },
    setLoading(state: AssetsState, loading: boolean) {
      state.loading = loading;
    },
    reset(state: AssetsState) {
      Object.assign(state, getInitialAssetsState());
    },
  },

  actions: {
    async loadAssets(
      { commit, state },
      params
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const replacements = {
          site_id: "property.property_branch_id",
          category_id: "asset_category_id",
          make: "brand",
        };

        const axiosResponse: AxiosResponse = await client.get("/assets", {
          params: {
            ...defaultAssetsApiParams,
            ...convertFiltersToQuery(state.filters, "AND", replacements),
            ...params,
          },
        });
        if (axiosResponse.data) {
          const response: AssetsApiResponseInterface = axiosResponse.data;

          commit("setAssets", response.data || []);

          if (response.meta) {
            commit("setMeta", response.meta);
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async createAsset(
      { commit },
      asset: AssetInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.post(
          "/assets",
          asset
        );
        if (axiosResponse.data) {
          const response: AssetsApiResponseInterface = axiosResponse.data;
          if (!response.data) {
            throw new Error("Asset failed to save, please try again");
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async updateAsset(
      { commit },
      asset: AssetInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        if (!asset.id) {
          throw new Error("No asset ID specified for update");
        }

        const axiosResponse: AxiosResponse = await client.patch(
          `/assets/${asset.id}`,
          asset
        );
        if (axiosResponse.data) {
          const response: AssetsApiResponseInterface = axiosResponse.data;
          if (response.data) {
            //@todo check that the asset coming back matches the one we sent
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async deleteAsset({ commit }, asset: AssetInterface) {
      commit("setLoading", true);
      try {
        if (!asset.id) {
          throw new Error("No asset ID specified for deletion");
        }

        const axiosResponse: AxiosResponse = await client.delete(
          `/assets/${asset.id}`
        );
        if (![200, 204].includes(axiosResponse.status)) {
          throw new Error("Failed to delete asset - please try again");
        }

        return true;
      } finally {
        commit("setLoading", false);
      }
    },

    async loadMedia(
      { commit },
      asset: AssetInterface
    ): Promise<AxiosResponse | unknown> {
      try {
        if (!asset.id) {
          throw new Error("No asset ID specified when loading media");
        }

        const axiosResponse: AxiosResponse = await client.get(
          `/assets/${asset.id}/media`
        );
        if (axiosResponse.data) {
          const response: AssetsApiResponseInterface = axiosResponse.data;

          commit("setMedia", response.data || []);
        }

        return axiosResponse;
      } catch (error: AxiosError | Error | unknown) {
        console.error(error);
      }
    },

    async uploadMedia(
      { commit, state },
      { asset, media }
    ): Promise<AxiosResponse | unknown> {
      const axiosResponse: AxiosResponse = await client.post(
        `/assets/${asset.id}/media`,
        media,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (axiosResponse.data) {
        if (![200, 204].includes(axiosResponse.status)) {
          throw new Error("Failed to upload asset media - please try again");
        }

        const stateMedia = [...state.media];
        media.file = media.file.name;
        stateMedia.push(media);
        commit("setMedia", stateMedia);
      }

      return axiosResponse;
    },

    //@todo separate asset media into separate Vuex store (or don't use at all)
    async deleteAssetMedia({ commit, state }, media: AssetMediaInterface) {
      if (!media.id) {
        throw new Error("No asset media ID specified for deletion");
      }

      const axiosResponse: AxiosResponse = await client.delete(
        `/asset_media/${media.id}`
      );
      if (![200, 204].includes(axiosResponse.status)) {
        throw new Error("Failed to delete asset media - please try again");
      }

      commit(
        "setMedia",
        _.filter(state.media, (m) => m.id !== media.id)
      );

      return true;
    },
  },
};

export default assetsModule;
