var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-button',{staticClass:"mb-2",attrs:{"to":{ name: 'create_asset' },"variant":"primary","block":"","data-test":"add-asset-button"}},[_c('b-icon-plus-circle',{staticClass:"mr-2 show-collapsed"}),_vm._v("Add Asset ")],1),_c('hr',{staticClass:"mt-4 mb-4"}),_c('b-form',{attrs:{"data-test":"asset-filters"}},[_c('b-form-group',{attrs:{"label":"Keyword Search","label-for":"asset-search"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',[_c('b-icon-search')],1)],1),_c('b-input',{attrs:{"id":"asset-search","name":"asset-search","placeholder":"Search"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('SiteSelect',{attrs:{"id":"asset-site-filter","selected":_vm.filters.branch_id},on:{"update:selected":function($event){return _vm.$set(_vm.filters, "branch_id", $event)},"input":function($event){_vm.filters.branch_id = $event}}}),_c('PropertyAutocomplete',{attrs:{"selected":_vm.filters.property_id},on:{"hit":function($event){_vm.filters.property_id = $event.property_id}}}),_c('CategorySelect',{attrs:{"id":"asset-category-filter","selected":_vm.filters.category_id},on:{"input":function($event){_vm.filters.category_id = $event}}}),_c('b-form-group',{attrs:{"label":"Make","label-for":"asset-make"}},[_c('b-input',{attrs:{"id":"asset-make","name":"asset-make","data-test":"asset-filter-make-input"},model:{value:(_vm.filters.make),callback:function ($$v) {_vm.$set(_vm.filters, "make", $$v)},expression:"filters.make"}})],1),_c('b-form-group',{attrs:{"label":"Model","label-for":"asset-model"}},[_c('b-input',{attrs:{"id":"asset-model","name":"asset-model","data-test":"asset-filter-model-input"},model:{value:(_vm.filters.model),callback:function ($$v) {_vm.$set(_vm.filters, "model", $$v)},expression:"filters.model"}})],1),_c('b-button',{attrs:{"disabled":_vm.loading ||
        Object.values(_vm.filters).every(
          (filter) => filter === undefined || !filter
        ),"data-test":"asset-filter-submit","type":"submit","variant":"secondary","block":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.filter(_vm.filters)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('b-icon-filter'),_vm._v(" Filter Results ")],1),_c('b-button',{attrs:{"disabled":_vm.loading ||
        Object.values(_vm.filters).every(
          (filter) => filter === undefined || !filter
        ),"data-test":"asset-filter-clear","variant":"outline-danger","block":""},on:{"click":_vm.clearFilters}},[_c('b-icon-x-circle'),_vm._v(" Clear Filters ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }