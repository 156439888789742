
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import LoginForm from "./LoginForm.vue";
import { BvModal } from "bootstrap-vue";

@Component({
  components: { LoginForm },
})
export default class LoginModal extends Vue {
  @Ref() readonly loginModal!: BvModal;

  handleUserLoggedIn() {
    this.loginModal?.hide();
  }
}
