
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { SelectOption } from "@/api/defaults";
import { namespace } from "vuex-class";
import { CategoryInterface } from "@/interfaces/CategoryInterface";

const categories = namespace("categories");

@Component({})
export default class CategorySelect extends Vue {
  @Prop({
    required: false,
    default: undefined,
    type: Number,
  })
  private selected!: number | undefined;

  @categories.Action("getCategories")
  private getCategories!: ActionMethod;

  @categories.State("loading")
  private loading?: boolean;

  private categories: SelectOption[] = [];

  private state: null | boolean = null;

  async mounted() {
    try {
      const categories = await this.getCategories();
      if (categories) {
        this.categories.push({
          value: undefined,
          text: "All Categories",
          disabled: true,
        });
        categories.forEach((category: CategoryInterface) => {
          this.categories.push({
            value: category.asset_category_id,
            text: category.name,
            class: "parent",
          });

          if (category.children) {
            category.children.forEach((category: CategoryInterface) => {
              this.categories.push({
                value: category.asset_category_id,
                text: "→ " + category.name,
              });
            });
          }
        });
      }
    } catch (error: Error | unknown) {
      this.state = false;
      this.categories.push({
        value: undefined,
        text: "Error: Unable to load category data",
      });
    }
  }
}
