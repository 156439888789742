<template>
  <div id="app" class="container-fluid p-0 h-100">
    <SiteHeader />
    <router-view />
    <SiteFooter />
    <LoginModal />
    <RegisterModal />
  </div>
</template>

<script>
import SiteHeader from "@/components/layout/SiteHeader";
import SiteFooter from "@/components/layout/SiteFooter";
import LoginModal from "@/components/login/LoginModal";
import RegisterModal from "@/components/register/RegisterModal";

export default {
  components: { RegisterModal, LoginModal, SiteFooter, SiteHeader },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
@import "assets/scss/app.scss";
@import "~@/assets/scss/vendors/bootstrap-vue/index";

html,
body {
  height: 100%;
}
</style>
