
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SiteInterface } from "@/interfaces/SiteInterface";
import SiteForm from "@/components/sites/form/SiteForm.vue";

@Component({
  components: { SiteForm },
})
export default class SiteModal extends Vue {
  @Prop({ required: true }) site?: SiteInterface;

  handleSiteSaved(site: SiteInterface) {
    this.$bvModal.hide("site-modal");
    this.$emit("saved", site);
  }
}
