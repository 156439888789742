<template>
  <a href="https://imgflip.com/i/6rmizl">
    <img
      class="img-fluid"
      src="https://i.imgflip.com/6rmizl.jpg"
      alt="register img"
    />
  </a>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class RegisterForm extends Vue {
  register() {
    // const user = useUser();
    // user.register({});
  }
}
</script>
