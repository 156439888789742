
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AssetInterface } from "@/interfaces/AssetInterface";
import AssetForm from "@/components/assets/form/AssetForm.vue";
import AssetMedia from "@/components/assets/form/AssetMedia.vue";

@Component({
  components: { AssetMedia, AssetForm },
})
export default class AssetModal extends Vue {
  @Prop({ required: true }) asset?: AssetInterface;

  handleAssetSaved(asset: AssetInterface) {
    this.$bvModal.hide("asset-modal");
    this.$emit("saved", asset);
  }
}
