
import PropertiesTable from "@/components/properties/PropertiesTable.vue";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import AssetFilters from "@/components/assets/form/AssetFilters.vue";
import PropertyFilters from "@/components/properties/form/PropertyFilters.vue";

@Component({
  components: { PropertyFilters, AssetFilters, Sidebar, PropertiesTable },
})
export default class PropertiesView extends Vue {}
