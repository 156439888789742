
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { SelectOption } from "@/api/defaults";
import { namespace } from "vuex-class";
import { CategoryInterface } from "@/interfaces/CategoryInterface";
import { ValidationRule } from "vee-validate/dist/types/types";

const categories = namespace("categories");

@Component({})
export default class CategorySelectValidated extends Vue {
  @Prop({
    required: false,
    default: undefined,
    type: Number,
  })
  private selected!: number | undefined; //@todo investigate switching to using watchers so the parent can use v-model instead of this + listener

  @Prop({ required: true, type: String })
  private readonly id!: string;

  @Prop({ required: false, type: String, default: "Category" })
  private readonly label!: string;

  @Prop({ required: false, default: "All Categories" })
  private defaultOption!: string;

  @Prop({ required: false, default: "", type: [Object, String] })
  private rules!: string | ValidationRule[]; //@todo figure out why valid state is true even on initial load of component, rather than null

  @categories.Action("getCategories")
  private getCategories!: ActionMethod;

  @categories.State("loading")
  private loading?: boolean;

  private categories: SelectOption[] = [];

  async created() {
    try {
      const categories = await this.getCategories();
      if (categories) {
        this.categories.push({
          value: undefined,
          text: this.defaultOption,
          disabled: true,
        });
        categories.forEach((category: CategoryInterface) => {
          this.categories.push({
            value: category.asset_category_id,
            text: category.name,
            class: "parent",
          });

          if (category.children) {
            category.children.forEach((category: CategoryInterface) => {
              this.categories.push({
                value: category.asset_category_id,
                text: "→ " + category.name,
              });
            });
          }
        });
      }
    } catch (error: Error | unknown) {
      this.categories.push({
        value: undefined,
        text: "No Category Data",
      });
    }
  }
}
