
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ContactInterface } from "@/interfaces/ContactInterface";
import ContactForm from "@/components/contacts/form/ContactForm.vue";

@Component({
  components: { ContactForm },
})
export default class ContactModal extends Vue {
  @Prop({ required: true }) contact?: ContactInterface;

  handleContactSaved(contact: ContactInterface) {
    this.$bvModal.hide("contact-modal");
    this.$emit("saved", contact);
  }
}
