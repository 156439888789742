
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { ContactInterface } from "@/interfaces/ContactInterface";
import { ValidationFlags } from "vee-validate/dist/types/types";
import { AxiosError } from "axios";
import { handleError } from "@/api/responseHandler";
import CategorySelectValidated from "@/components/categories/CategorySelectValidated.vue";
import PropertyAutocomplete from "@/components/properties/form/PropertyAutocomplete.vue";

const contacts = namespace("contacts");
@Component({
  components: { PropertyAutocomplete, CategorySelectValidated },
})
export default class ContactForm extends Vue {
  @contacts.Action("createContact")
  private createContact!: ActionMethod;

  @contacts.Action("updateContact")
  private updateContact!: ActionMethod;

  @contacts.State("loading")
  private loading?: boolean;

  @Prop({ required: true })
  public contact?: ContactInterface;

  async save() {
    if (this.contact) {
      const contact = this.contact;
      try {
        !contact.id
          ? await this.createContact(this.contact)
          : await this.updateContact(this.contact);

        this.$emit("saved", this.contact);
        this.$toast.success("Contact saved successfully");

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
