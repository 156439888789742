
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { contactPlaceholder } from "@/store/contacts";
import {
  ContactApiResponseInterface,
  ContactInterface,
} from "@/interfaces/ContactInterface";
import ContactModal from "@/components/contacts/modal/ContactModal.vue";
import DeleteContactConfirmation from "@/components/contacts/modal/DeleteContactConfirmation.vue";
import {
  ApiPaginationInterface,
  ApiQueryInterface,
} from "@/interfaces/ApiInterface";
import { BTable, BvTableCtxObject } from "bootstrap-vue";
import { handleError } from "@/api/responseHandler";
import client from "@/api/client";
import { AxiosResponse } from "axios";
import { Route } from "vue-router";

const contacts = namespace("contacts");
@Component({
  components: { DeleteContactConfirmation, ContactModal },
})
export default class ContactsTable extends Vue {
  @contacts.Action("loadContacts")
  private loadContacts!: ActionMethod;

  @contacts.State("contacts")
  private contacts?: ContactInterface[];

  @contacts.State((state) => state.meta.pagination)
  private pagination?: ApiPaginationInterface;

  @contacts.State((state) => state.filters)
  private filters?: ApiQueryInterface;

  @contacts.State("loading")
  private loading?: boolean;

  private sortBy = "updated_at";
  private sortDesc = true;

  private fields = [
    {
      key: "name",
      label: "Name",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "type",
      label: "Type",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "address",
      label: "Address",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "email",
      label: "Email",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "phone",
      label: "Phone",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false,
      toggleable: false,
      visible: true,
    },
  ];

  private contactModel?: ContactInterface = contactPlaceholder;

  @Watch("$route", { deep: true, immediate: true })
  async handleNavigation(route: Route) {
    if (route.name === "create_contact") {
      this.createContact();
    }

    if (route.name === "edit_contact") {
      if (!route.params.id) {
        this.$toast.error("No contact ID present in the URL");
        return false;
      }

      const contactId: string = route.params?.id;
      let contact: ContactInterface | null = null;
      await client
        .get(`/contacts/${contactId}`)
        .then((response: AxiosResponse) => {
          if (response.data) {
            const responseData: ContactApiResponseInterface = response.data;
            contact = responseData.data;
          }
        });

      if (!contact) {
        this.$toast.error(`Unable to find contact ID ${contactId}`);
        return false;
      }

      this.editContact(contact);
    }
  }

  get visibleFields() {
    return this.fields.filter((field) => field.visible);
  }

  get toggleableFields() {
    return this.fields.filter((field) => field.toggleable);
  }

  async provider(context: BvTableCtxObject) {
    try {
      await this.loadContacts({
        limit: context.perPage,
        page: context.currentPage,
        orderBy: context.sortBy,
        sortedBy: context.sortDesc ? "desc" : "asc",
      });
    } catch (error) {
      handleError(error, {});
    }

    return this.contacts;
  }

  refresh() {
    if (this.$refs.contactsTable) {
      const table: BTable = this.$refs.contactsTable as BTable;
      table.refresh();
    } else {
      this.$log.error("Unable to find contacts table to refresh");
    }
  }

  getTrAttribute(item: ContactInterface, type: string) {
    if (!item || type !== "row") {
      return null;
    }

    return { "data-test": `contact-table__contact-${item.id}` };
  }

  editContact(contact: ContactInterface) {
    this.contactModel = Object.assign({}, contact);
    this.$bvModal.show("contact-modal");
  }

  createContact() {
    this.contactModel = Object.assign({}, contactPlaceholder);
    this.$bvModal.show("contact-modal");
  }

  deleteContact(contact: ContactInterface) {
    this.contactModel = Object.assign({}, contact);
    this.$bvModal.show("delete-contact-modal");
  }

  reset() {
    this.$router.push({ name: "contacts" });
    this.resetContact();
  }

  resetContact() {
    this.contactModel = Object.assign({}, contactPlaceholder);
  }
}
