import { Module } from "vuex";
import {
  ContactFilterInterface,
  ContactInterface,
  ContactsApiResponseInterface,
} from "@/interfaces/ContactInterface";
import { ApiMetaInterface } from "@/interfaces/ApiInterface";
import client from "@/api/client";
import { RootState } from "@/store/index";
import { AxiosResponse } from "axios";
import { convertFiltersToQuery, Filter, metaPlaceholder } from "@/api/defaults";

const defaultApiFields = [
  "id",
  "property_id",
  "contact_category_id",
  "category",
  "status",
  "reference",
];

const defaultContactsApiParams = {
  include: "",
  filter: defaultApiFields.join(";"),
  limit: 10,
};

export const contactPlaceholder: ContactInterface = {
  type: "Contractor",
  name: "",
  address_line_1: "",
  address_line_2: "",
  address_town: "",
  address_country: "England",
  address_postcode: "",
  telephone_mobile: "",
  telephone_landline: "",
  email: "",
};

export interface ContactsState {
  contacts: ContactInterface[] | null;
  filters: Filter[];
  meta: ApiMetaInterface | null;
  loading: boolean;
}

export const getInitialContactsState = () => ({
  contacts: [],
  filters: [],
  loading: false,
  meta: metaPlaceholder,
});

const contactsModule: Module<ContactsState, RootState> = {
  namespaced: true,

  state: (): ContactsState => getInitialContactsState(),

  mutations: {
    setContacts(state: ContactsState, contacts: ContactInterface[]) {
      state.contacts = contacts;
    },
    setFilters(state: ContactsState, filters: ContactFilterInterface) {
      state.filters = [];
      if (filters.search) {
        state.filters.push({ search: filters.search });
      }
    },
    setMeta(state: ContactsState, meta: ApiMetaInterface) {
      state.meta = meta;
    },
    setLoading(state: ContactsState, loading: boolean) {
      state.loading = loading;
    },
    reset(state: ContactsState) {
      Object.assign(state, getInitialContactsState());
    },
  },

  actions: {
    async loadContacts(
      { commit, state },
      params
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.get("/contacts", {
          params: {
            ...defaultContactsApiParams,
            ...convertFiltersToQuery(state.filters, "AND"),
            ...params,
          },
        });
        if (axiosResponse.data) {
          const response: ContactsApiResponseInterface = axiosResponse.data;

          commit("setContacts", response.data || []);

          if (response.meta) {
            commit("setMeta", response.meta);
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async createContact(
      { commit },
      contact: ContactInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.post(
          "/contacts",
          contact
        );
        if (axiosResponse.data) {
          const response: ContactsApiResponseInterface = axiosResponse.data;
          if (!response.data) {
            throw new Error("Contact failed to save, please try again");
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async updateContact(
      { commit },
      contact: ContactInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        if (!contact.id) {
          throw new Error("No contact ID specified for update");
        }

        const axiosResponse: AxiosResponse = await client.patch(
          `/contacts/${contact.id}`,
          contact
        );
        if (axiosResponse.data) {
          const response: ContactsApiResponseInterface = axiosResponse.data;
          if (response.data) {
            //@todo check that the contact coming back matches the one we sent
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async deleteContact({ commit }, contact: ContactInterface) {
      commit("setLoading", true);
      try {
        if (!contact.id) {
          throw new Error("No contact ID specified for deletion");
        }

        const axiosResponse: AxiosResponse = await client.delete(
          `/contacts/${contact.id}`
        );
        if (![200, 204].includes(axiosResponse.status)) {
          throw new Error("Failed to delete contact - please try again");
        }

        return true;
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default contactsModule;
