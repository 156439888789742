
import HeaderNavigation from "./HeaderNavigation.vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { HeaderNavigation },
})
export default class Sidebar extends Vue {
  @Prop({ required: false, type: String, default: "sidebar" })
  readonly id!: string;

  private isCollapsed = false;
}
