
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ActionContext } from "vuex";
import { namespace } from "vuex-class";
import { UserState } from "@/store/user";

const user = namespace("user");

@Component
export default class HeaderNavigation extends Vue {
  @user.Getter("isLoggedIn") isLoggedIn!: boolean;
  @user.Action("logout") logout!: ActionContext<UserState, UserState>;
}
