import { ApiMetaInterface, ApiQueryInterface } from "@/interfaces/ApiInterface";

export const metaPlaceholder: ApiMetaInterface = {
  custom: undefined,
  include: [],
  pagination: {
    per_page: 10,
    current_page: 1,
    total: 0,
    count: 0,
    total_pages: 1,
  },
};

export function convertFiltersToQuery(
  filters: Filter[],
  mode: "AND" | "OR" = "AND",
  fieldReplacements?: { [find: string]: string }
): ApiQueryInterface {
  const fields: string[] = [];
  const conditions: string[] = [];
  filters.forEach((filter) => {
    if (filter.field) {
      const field =
        fieldReplacements && fieldReplacements[filter.field]
          ? fieldReplacements[filter.field]
          : filter.field;
      fields.push(`${field}:${filter.search}`);
      conditions.push(`${field}:${filter.condition || "="}`);
    } else {
      fields.push(filter.search.toString());
    }
  });

  return {
    search: fields.join(";"),
    searchFields: conditions.join(";"),
    searchJoin: mode,
  };
}

export function convertFilterQueryToFilters(
  filterArray: Filter[],
  fieldReplacements?: { [find: string]: string }
) {
  const filters: { [key: string]: string | number } = {};
  for (const key in filterArray) {
    const filter: Filter = filterArray[key];
    if (filter.field) {
      filters[
        fieldReplacements && fieldReplacements[filter.field]
          ? fieldReplacements[filter.field]
          : filter.field
      ] = filter.search;
    }
  }

  return filters;
}

export interface Filter {
  search: string | number;
  field?: string;
  condition?: "=" | ">=" | "<=" | "!=" | "like" | "not like";
}

export interface SelectOption {
  value?: number;
  text?: string;
  disabled?: boolean;
  class?: string;
}
