
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import { namespace } from "vuex-class";
import { ActionMethod } from "vuex";
import { assetPlaceholder } from "@/store/assets";
import {
  AssetApiResponseInterface,
  AssetInterface,
} from "@/interfaces/AssetInterface";
import AssetModal from "@/components/assets/modal/AssetModal.vue";
import DeleteAssetConfirmation from "@/components/assets/modal/DeleteAssetConfirmation.vue";
import {
  ApiPaginationInterface,
  ApiQueryInterface,
} from "@/interfaces/ApiInterface";
import { BTable, BvTableCtxObject } from "bootstrap-vue";
import { handleError } from "@/api/responseHandler";
import client from "@/api/client";
import { AxiosResponse } from "axios";
import { Route } from "vue-router";

const assets = namespace("assets");
@Component({
  components: { DeleteAssetConfirmation, AssetModal },
})
export default class AssetsTable extends Vue {
  @assets.Action("loadAssets")
  private loadAssets!: ActionMethod;

  @assets.State("assets")
  private assets?: AssetInterface[];

  @assets.State((state) => state.meta.pagination)
  private pagination?: ApiPaginationInterface;

  @assets.State((state) => state.filters)
  private filters?: ApiQueryInterface;

  @assets.State("loading")
  private loading?: boolean;

  private sortBy = "updated_at";
  private sortDesc = true;

  private fields = [
    {
      key: "reference",
      label: "Reference",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "category.name",
      label: "Category",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "brand",
      label: "Make",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "model",
      label: "Model",
      toggleable: true,
      visible: true,
    },
    {
      key: "supplier",
      label: "Supplier",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "purchase_price",
      label: "Price",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "purchase_date",
      label: "Purchased",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      //@todo fix invoice_number not saving
      key: "invoice_number",
      label: "Invoice Ref",
      toggleable: true,
      visible: true,
    },
    {
      key: "under_warranty",
      label: "Warranty",
      toggleable: true,
      visible: true,
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "created_at",
      label: "Created",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "updated_at",
      label: "Updated",
      sortable: true,
      toggleable: true,
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false,
      toggleable: false,
      visible: true,
    },
  ];

  private assetModel?: AssetInterface = assetPlaceholder;

  @Watch("$route", { deep: true, immediate: true })
  async handleNavigation(route: Route) {
    if (route.name === "create_asset") {
      this.createAsset();
    }

    if (route.name === "edit_asset") {
      if (!route.params.id) {
        this.$toast.error("No asset ID present in the URL");
        return false;
      }

      const assetId: string = route.params?.id;
      let asset: AssetInterface | null = null;
      await client.get(`/assets/${assetId}`).then((response: AxiosResponse) => {
        if (response.data) {
          const responseData: AssetApiResponseInterface = response.data;
          asset = responseData.data;
        }
      });

      if (!asset) {
        this.$toast.error(`Unable to find asset ID ${assetId}`);
        return false;
      }

      this.editAsset(asset);
    }
  }

  get visibleFields() {
    return this.fields.filter((field) => field.visible);
  }

  get toggleableFields() {
    return this.fields.filter((field) => field.toggleable);
  }

  async provider(context: BvTableCtxObject) {
    try {
      await this.loadAssets({
        limit: context.perPage,
        page: context.currentPage,
        orderBy: context.sortBy,
        sortedBy: context.sortDesc ? "desc" : "asc",
      });
    } catch (error) {
      handleError(error, {});
    }

    return this.assets;
  }

  refresh() {
    if (this.$refs.assetsTable) {
      const table: BTable = this.$refs.assetsTable as BTable;
      table.refresh();
    } else {
      this.$log.error("Unable to find assets table to refresh");
    }
  }

  displayDate(date: string, includeTimestamp = false, defaultValue = "-") {
    //@todo create a vue helper for this
    return !date
      ? defaultValue
      : moment(date).format(
          "DD/MM/YYYY" + (includeTimestamp ? " HH:mm:ss" : "")
        );
  }

  getAssetStatusVariant(status: string) {
    switch (status) {
      case "active":
        return "success";

      case "draft":
      default:
        return "";
    }
  }

  getTrAttribute(item: AssetInterface, type: string) {
    if (!item || type !== "row") {
      return null;
    }

    return { "data-test": `asset-table__asset-${item.id}` };
  }

  editAsset(asset: AssetInterface) {
    this.assetModel = Object.assign({}, asset);
    this.$bvModal.show("asset-modal");
  }

  createAsset() {
    this.assetModel = Object.assign({}, assetPlaceholder);
    this.$bvModal.show("asset-modal");
  }

  deleteAsset(asset: AssetInterface) {
    this.assetModel = Object.assign({}, asset);
    this.$bvModal.show("delete-asset-modal");
  }

  reset() {
    this.$router.push({ name: "assets" });
    this.resetAsset();
  }

  resetAsset() {
    this.assetModel = Object.assign({}, assetPlaceholder);
  }
}
