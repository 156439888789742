import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: process.env.VUE_APP_API_TIMEOUT,
  timeout: 10000, //@todo check performance after HTTP/2 update and uncomment the above
  headers: { Authorization: "" },
};

const getClientTokenFromStore = () => {
  let token = "";
  if (localStorage.vuex) {
    const storage = JSON.parse(window.localStorage.vuex);
    if (storage) {
      const user = storage.user.user;
      if (user) {
        token = storage.user.user.token;
      }
    }
  }
  return token;
};

const client = axios.create(config);
client.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    const token = getClientTokenFromStore();
    config.headers.Authorization = `Bearer ${token}`;
  }
  Vue.$log.debug("axios request", config);
  return config;
});
client.interceptors.response.use((response: AxiosResponse) => {
  Vue.$log.debug("axios response", response);
  return response;
});

export default client;
