import { Module } from "vuex";
import {
  CategoriesApiResponseInterface,
  CategoryInterface,
} from "@/interfaces/CategoryInterface";
import client from "@/api/client";
import { RootState } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { handleError } from "@/api/responseHandler";

export interface CategoriesState {
  categories: CategoryInterface[] | null;
  loading: boolean;
}

const initialState = {
  categories: [],
  loading: false,
};

const categoriesModule: Module<CategoriesState, RootState> = {
  namespaced: true,

  state: (): CategoriesState => initialState,

  mutations: {
    setCategories(state: CategoriesState, categories: CategoryInterface[]) {
      state.categories = categories;
    },
    setLoading(state: CategoriesState, loading: boolean) {
      state.loading = loading;
    },
    reset(state: CategoriesState) {
      Object.assign(state, initialState);
    },
  },

  actions: {
    async getCategories(context, params): Promise<CategoryInterface[] | null> {
      if (!context.state.categories || context.state.categories.length < 1) {
        try {
          await context.dispatch("loadCategories", params);
        } catch (error: AxiosError | Error | unknown) {
          handleError(error);
          throw error;
        }
      }

      return context.state.categories;
    },

    async loadCategories({ commit }, params): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.get(
          "/asset_categories",
          {
            params: params,
          }
        );
        if (axiosResponse.data) {
          const response: CategoriesApiResponseInterface = axiosResponse.data;

          if (response.data) {
            commit("setCategories", response.data);
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default categoriesModule;
