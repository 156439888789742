
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { MutationMethod } from "vuex";
import { namespace } from "vuex-class";
import { ContactFilterInterface } from "@/interfaces/ContactInterface";
import { convertFilterQueryToFilters, Filter } from "@/api/defaults";
import CategorySelect from "@/components/categories/CategorySelect.vue";
import SiteSelect from "@/components/sites/form/SiteSelect.vue";
import PropertyAutocomplete from "@/components/properties/form/PropertyAutocomplete.vue";
import _ from "lodash";

const contacts = namespace("contacts");

const getDefaultFilters = (): ContactFilterInterface => ({
  search: "",
});

@Component({
  components: { PropertyAutocomplete, SiteSelect, CategorySelect },
})
export default class ContactFilters extends Vue {
  @contacts.State("loading")
  private loading?: boolean;

  @contacts.Mutation("setFilters")
  private setFilters!: MutationMethod;

  @contacts.State("filters")
  private initialFilters!: Filter[];

  private filters: ContactFilterInterface = getDefaultFilters();

  created() {
    this.loadInitialFilters();
  }

  private filter(filters: ContactFilterInterface) {
    this.setFilters(filters);
    this.mapFiltersToUrl(filters);
  }

  private clearFilters() {
    this.filters = getDefaultFilters();
    this.setFilters({});
    this.mapFiltersToUrl({});
  }

  private routeAlreadyLoaded(route) {
    const existing = {
      name: this.$route.name,
      query: this.$route.query,
    };
    return _.isEqual(route, existing);
  }

  private mapFiltersToUrl(filters: ContactFilterInterface) {
    const route = {
      name: this.$route.name ?? "",
      query: _.pickBy(filters, _.identity) as never,
    };
    if (!this.routeAlreadyLoaded(route)) {
      this.$router.push(route);
    }
  }

  private loadInitialFilters() {
    let filters = {};
    if (this.initialFilters) {
      const contactFilters = convertFilterQueryToFilters(this.initialFilters);

      filters = _.merge(getDefaultFilters(), contactFilters);
      this.mapFiltersToUrl(contactFilters);
    }

    if (this.$route.query.length) {
      filters = _.merge(getDefaultFilters(), this.$route.query);
    }

    this.filters = filters;
    this.setFilters(filters);
  }
}
