import VueLogger from "vuejs-logger";
import Vue from "vue";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  logLevel: process.env.VUE_APP_LOG_LEVEL
    ? process.env.VUE_APP_LOG_LEVEL
    : isProduction
    ? "error"
    : "debug",
  showLogLevel: true,
  showConsoleColors: true,
  showMethodName: true,
};

Vue.use(VueLogger, options);
