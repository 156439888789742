
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { namespace } from "vuex-class";
import {
  PropertiesApiResponseInterface,
  PropertyApiResponseInterface,
  PropertyFilterInterface,
  PropertyInterface,
} from "@/interfaces/PropertyInterface";
import client from "@/api/client";
import { AxiosResponse } from "axios";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { convertFiltersToQuery } from "@/api/defaults";
import {
  convertFilters,
  defaultPropertiesApiParams,
  getDisplayName,
} from "@/store/properties";
import { Debounce } from "vue-debounce-decorator";

const properties = namespace("properties");

@Component({
  components: { VueTypeaheadBootstrap },
})
export default class PropertyAutocomplete extends Vue {
  @Prop({
    required: false,
    default: undefined,
    type: Number,
  })
  private selected!: number | undefined;

  @properties.Action("getProperties")
  private getProperties!: ActionMethod;

  @properties.State("properties")
  private properties?: PropertyInterface[];

  private loading = false;

  private state: null | boolean = null;

  private query = "";

  private results: PropertyInterface[] = [];

  private selectedProperty: PropertyInterface | undefined = undefined;

  async mounted() {
    if (this.selected) {
      try {
        this.loading = true;
        this.selectedProperty = await this.loadProperty(this.selected);
        if (this.selectedProperty) {
          this.query = this.displayName(this.selectedProperty);
        }
      } catch (error) {
        this.state = false;
      } finally {
        this.loading = false;
      }
    }
  }

  //@todo figure out why API call is triggered on selecting a result
  // maybe have to do this.query !== this.selectedProperty using @hit() (see docs)
  @Debounce(500)
  async searchProperties(): void {
    if (!this.query.length) return [];

    let properties: PropertyInterface[] = [];
    const filters: PropertyFilterInterface = {
      property_feed_ref: this.query,
      property_address_name: this.query,
      property_address_1: this.query,
      property_address_2: this.query,
      property_address_postcode: this.query,
    };

    this.loading = true;
    try {
      const response: AxiosResponse = await client.get("/properties", {
        params: {
          ...defaultPropertiesApiParams,
          ...{ include: "" }, // Don't need branch data
          ...convertFiltersToQuery(convertFilters(filters), "OR"),
        },
      });

      if (response.data) {
        const responseData: PropertiesApiResponseInterface = response.data;
        properties = responseData.data;
      }
    } catch (error: AxiosError | Error | unknown) {
      this.state = false;
      this.query = "Failed to load property data";
      this.$log.error(error);
    } finally {
      this.results = properties ?? [];
      this.loading = false;
    }
  }

  async loadProperty(id: number): Promise<PropertyInterface | undefined> {
    let property = undefined;
    if (this.properties?.length) {
      let results: PropertyInterface[] = this.properties.filter(
        (property) => property.property_id == id
      );
      if (results && results.length === 1) {
        property = results[0];
      }
    }

    if (!property) {
      await client.get(`/properties/${id}`).then((response: AxiosResponse) => {
        if (response.data) {
          const responseData: PropertyApiResponseInterface = response.data;
          property = responseData.data;
        }
      });
    }

    return property;
  }

  displayName(property: PropertyInterface): string {
    return property ? getDisplayName(property) : "";
  }
}
