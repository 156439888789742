import { Module } from "vuex";
import {
  PropertiesApiResponseInterface,
  PropertyFilterInterface,
  PropertyInterface,
} from "@/interfaces/PropertyInterface";
import { ApiMetaInterface } from "@/interfaces/ApiInterface";
import client from "@/api/client";
import { RootState } from "@/store/index";
import { AxiosResponse } from "axios";
import { convertFiltersToQuery, Filter, metaPlaceholder } from "@/api/defaults";

export const propertyPlaceholder: PropertyInterface = {
  property_region: "england",
  property_address_name: "",
  property_branch_id: 1,
  property_address_postcode: "",
  property_address_country: "United Kingdom",
  property_furnished: false,
};

const defaultApiFields = [
  "property_id",
  "property_feed_ref",
  "property_address_1",
  "property_address_2",
  "property_address_country",
  "property_address_county",
  "property_address_name",
  "property_address_postcode",
  "property_address_town",
  "property_branch_id",
  "property_created",
  "property_deleted_at",
  "property_modified",
  "property_region",
  "property_status",
  "property_type",
  "branch",
  "meta",
];

export const defaultPropertiesApiParams = {
  include: "branch",
  filter: defaultApiFields.join(";"),
  limit: 5,
};

export interface PropertiesState {
  properties: PropertyInterface[] | null;
  filters: Filter[];
  meta: ApiMetaInterface | null;
  loading: boolean;
}

export const getInitialPropertiesState = () => ({
  properties: [],
  filters: [],
  loading: false,
  meta: metaPlaceholder,
});

export function getDisplayName(property: PropertyInterface) {
  return [
    property.property_address_name + " " + property.property_address_1,
    property.property_address_town,
    property.property_address_postcode,
  ]
    .filter(Boolean)
    .join(", ");
}

export function convertFilters(filters: PropertyFilterInterface): Filter[] {
  const apiFilters: Filter[] = [];
  if (filters.property_id) {
    apiFilters.push({
      field: "property_id",
      search: filters.property_id,
      condition: "=",
    });
  }

  if (filters.property_branch_id) {
    apiFilters.push({
      field: "property_branch_id",
      search: filters.property_branch_id,
      condition: "=",
    });
  }

  if (filters.property_feed_ref) {
    apiFilters.push({
      field: "property_feed_ref",
      search: filters.property_feed_ref,
      condition: "like",
    });
  }

  if (filters.property_address_name) {
    apiFilters.push({
      field: "property_address_name",
      search: filters.property_address_name,
      condition: "like",
    });
  }

  if (filters.property_address_1) {
    apiFilters.push({
      field: "property_address_1",
      search: filters.property_address_1,
      condition: "like",
    });
  }

  if (filters.property_address_2) {
    apiFilters.push({
      field: "property_address_2",
      search: filters.property_address_2,
      condition: "like",
    });
  }

  if (filters.property_address_postcode) {
    apiFilters.push({
      field: "property_address_postcode",
      search: filters.property_address_postcode,
      condition: "like",
    });
  }

  return apiFilters;
}

const propertiesModule: Module<PropertiesState, RootState> = {
  namespaced: true,

  state: (): PropertiesState => getInitialPropertiesState(),

  getters: {
    getById(state: PropertiesState, id: number) {
      return state.properties?.filter((property) => {
        return property.property_id === id;
      });
    },
  },

  mutations: {
    setProperties(state: PropertiesState, properties: PropertyInterface[]) {
      state.properties = properties;
    },
    setFilters(state: PropertiesState, filters: PropertyFilterInterface) {
      state.filters = convertFilters(filters);
    },
    setMeta(state: PropertiesState, meta: ApiMetaInterface) {
      state.meta = meta;
    },
    setLoading(state: PropertiesState, loading: boolean) {
      state.loading = loading;
    },
    reset(state: PropertiesState) {
      Object.assign(state, getInitialPropertiesState());
    },
  },

  actions: {
    async loadProperties(
      { commit, state },
      params
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.get("/properties", {
          params: {
            ...defaultPropertiesApiParams,
            ...convertFiltersToQuery(state.filters),
            ...params,
          },
        });
        if (axiosResponse.data) {
          const response: PropertiesApiResponseInterface = axiosResponse.data;

          if (response.data) {
            commit("setProperties", response.data);
          }

          if (response.meta) {
            commit("setMeta", response.meta);
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async createProperty(
      { commit },
      property: PropertyInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.post(
          "/properties",
          property
        );
        if (axiosResponse.data) {
          const response: PropertiesApiResponseInterface = axiosResponse.data;
          if (!response.data) {
            throw new Error("Property failed to save, please try again");
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async updateProperty(
      { commit },
      property: PropertyInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        if (!property.property_id) {
          throw new Error("No property ID specified for update");
        }

        const axiosResponse: AxiosResponse = await client.patch(
          `/properties/${property.property_id}`,
          property
        );
        if (axiosResponse.data) {
          const response: PropertiesApiResponseInterface = axiosResponse.data;
          if (response.data) {
            //@todo check that the property coming back matches the one we sent
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async deleteProperty({ commit }, property: PropertyInterface) {
      commit("setLoading", true);
      try {
        if (!property.property_id) {
          throw new Error("No property ID specified for deletion");
        }

        const axiosResponse: AxiosResponse = await client.delete(
          `/properties/${property.property_id}`
        );
        if (![200, 204].includes(axiosResponse.status)) {
          throw new Error("Failed to delete property - please try again");
        }

        return true;
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default propertiesModule;
