
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { SitesState } from "@/store/sites";
import { SiteInterface } from "@/interfaces/SiteInterface";
import { ValidationFlags } from "vee-validate/dist/types/types";
import { RootState } from "@/store";
import { AxiosError } from "axios";
import { handleError } from "@/api/responseHandler";

const sites = namespace("sites");

@Component
export default class SiteForm extends Vue {
  @sites.Action("createSite")
  private createSite!: ActionContext<SitesState, RootState>;

  @sites.Action("updateSite")
  private updateSite!: ActionContext<SitesState, RootState>;

  @sites.State("loading")
  private loading?: boolean;

  @Prop({ required: true })
  public site?: SiteInterface;

  async save() {
    if (this.site) {
      const site = this.site;
      try {
        !site.branch_id
          ? await this.createSite(this.site)
          : await this.updateSite(this.site);

        this.$emit("saved", this.site);
        this.$toast.success("Site saved successfully");

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }

  getValidationState({ dirty, validated, valid }: ValidationFlags) {
    return dirty || validated ? valid : null;
  }
}
