
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PropertyInterface } from "@/interfaces/PropertyInterface";
import PropertyForm from "@/components/properties/form/PropertyForm.vue";

@Component({
  components: { PropertyForm },
})
export default class PropertyModal extends Vue {
  @Prop({ required: true }) property?: PropertyInterface;

  handlePropertySaved(property: PropertyInterface) {
    this.$bvModal.hide("property-modal");
    this.$emit("saved", property);
  }
}
