
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ActionContext } from "vuex";
import { handleError } from "@/api/responseHandler";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { RootState } from "@/store";
import { ContactsState } from "@/store/contacts";
import { ContactInterface } from "@/interfaces/ContactInterface";

const contacts = namespace("contacts");

@Component
export default class DeleteContactConfirmation extends Vue {
  @Prop({ required: true })
  private contact?: ContactInterface;

  @contacts.Action("deleteContact")
  private commitDelete!: ActionContext<ContactsState, RootState>;

  async deleteContact() {
    if (this.contact) {
      const contact = this.contact;
      try {
        await this.commitDelete(contact);
        this.$toast.success("Contact deleted successfully");
        this.$emit("deleted", contact);

        return true;
      } catch (error: AxiosError | Error | unknown) {
        handleError(error, {});

        return false;
      }
    }
  }
}
