
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { SelectOption } from "@/api/defaults";
import { namespace } from "vuex-class";
import { SiteInterface } from "@/interfaces/SiteInterface";

const sites = namespace("sites");

@Component({})
export default class SiteSelect extends Vue {
  @Prop({
    required: false,
    default: undefined,
    type: Number,
  })
  private selected!: number | undefined;

  @sites.Action("getSites")
  private getSites!: ActionMethod;

  @sites.State("loading")
  private loading?: boolean;

  private sites: SelectOption[] = [];

  private state: null | boolean = null;

  async mounted() {
    try {
      const sites = await this.getSites();
      if (sites) {
        this.sites.push({
          value: undefined,
          text: "All Sites",
          disabled: true,
        });
        sites.forEach((site: SiteInterface) => {
          this.sites.push({
            value: site.branch_id,
            text: [
              site.branch_name,
              site.branch_address_1,
              site.branch_address_town,
              site.branch_address_postcode,
            ]
              .filter(Boolean)
              .join(", "),
          });
        });
      }
    } catch (error: Error | unknown) {
      this.state = false;
      this.selected = undefined;
      this.sites.push({
        value: undefined,
        text: "Error: Unable to load site data",
      });
    }
  }
}
