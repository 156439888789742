var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-button',{staticClass:"mb-2",attrs:{"to":{ name: 'create_property' },"variant":"primary","block":"","data-test":"property-add-button"}},[_c('b-icon-plus-circle',{staticClass:"mr-2 show-collapsed"}),_vm._v("Add Property ")],1),_c('hr',{staticClass:"mt-4 mb-4"}),_c('b-form',[_c('b-form-group',{attrs:{"label":"Keyword Search","label-for":"property-search"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',[_c('b-icon-search')],1)],1),_c('b-input',{attrs:{"id":"property-search","name":"property-search","placeholder":"Search","data-test":"property-search-filter"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('SiteSelect',{attrs:{"id":"property-site-filter","selected":_vm.filters.property_branch_id},on:{"update:selected":function($event){return _vm.$set(_vm.filters, "property_branch_id", $event)},"input":function($event){_vm.filters.property_branch_id = $event}}}),_c('b-button',{attrs:{"disabled":_vm.loading ||
        Object.values(_vm.filters).every(
          (filter) => filter === undefined || !filter
        ),"data-test":"property-filter-submit","type":"submit","variant":"secondary","block":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.filter(_vm.filters)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('b-icon-filter'),_vm._v(" Filter Results ")],1),_c('b-button',{attrs:{"disabled":_vm.loading ||
        Object.values(_vm.filters).every(
          (filter) => filter === undefined || !filter
        ),"data-test":"property-filter-clear","variant":"outline-danger","block":""},on:{"click":_vm.clearFilters}},[_c('b-icon-x-circle'),_vm._v(" Clear Filters ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }