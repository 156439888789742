import { Module } from "vuex";
import {
  SiteInterface,
  SitesApiResponseInterface,
} from "@/interfaces/SiteInterface";
import { ApiMetaInterface } from "@/interfaces/ApiInterface";
import client from "@/api/client";
import { RootState } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { metaPlaceholder } from "@/api/defaults";
import { handleError } from "@/api/responseHandler";

const defaultApiFields = [
  "branch_id",
  "branch_name",
  "branch_modified",
  "branch_created",
  "branch_telephone",
  "branch_email",
  "branch_address_1",
  "branch_address_2",
  "branch_address_town",
  "branch_address_county",
  "branch_address_postcode",
  "pagination",
];

const defaultSitesApiParams = {
  filter: defaultApiFields.join(";"),
  limit: 5,
};

export const sitePlaceholder: SiteInterface = {};

export interface SitesState {
  sites: SiteInterface[] | null;
  meta: ApiMetaInterface | null;
  loading: boolean;
}

const initialState = {
  sites: [],
  loading: false,
  meta: metaPlaceholder,
};

const sitesModule: Module<SitesState, RootState> = {
  namespaced: true,

  state: (): SitesState => initialState,

  mutations: {
    setSites(state: SitesState, sites: SiteInterface[]) {
      state.sites = sites;
    },
    setMeta(state: SitesState, meta: ApiMetaInterface) {
      state.meta = meta;
    },
    setLoading(state: SitesState, loading: boolean) {
      state.loading = loading;
    },
    reset(state: SitesState) {
      Object.assign(state, initialState);
    },
  },

  actions: {
    async getSites(context, params): Promise<SiteInterface[]> {
      if (!context.state.sites || context.state.sites.length < 1) {
        try {
          await context.dispatch("loadSites", params);
        } catch (error: AxiosError | Error | unknown) {
          handleError(error);
          throw error;
        }
      }

      return context.state.sites ?? [];
    },

    async loadSites({ commit }, params): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.get("/branches", {
          params: { ...defaultSitesApiParams, ...params },
        });
        if (axiosResponse.data) {
          const response: SitesApiResponseInterface = axiosResponse.data;

          if (response.data) {
            commit("setSites", response.data);
          }

          if (response.meta) {
            commit("setMeta", response.meta);
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async createSite(
      { commit },
      site: SiteInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        const axiosResponse: AxiosResponse = await client.post(
          "/branches",
          site
        );
        if (axiosResponse.data) {
          const response: SitesApiResponseInterface = axiosResponse.data;
          if (!response.data) {
            throw new Error("Site failed to save, please try again");
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async updateSite(
      { commit },
      site: SiteInterface
    ): Promise<AxiosResponse | unknown> {
      commit("setLoading", true);
      try {
        if (!site.branch_id) {
          throw new Error("No site ID specified for update");
        }

        const axiosResponse: AxiosResponse = await client.patch(
          `/branches/${site.branch_id}`,
          site
        );
        if (axiosResponse.data) {
          const response: SitesApiResponseInterface = axiosResponse.data;
          if (response.data) {
            //@todo check that the site coming back matches the one we sent
          }
        }

        return axiosResponse;
      } finally {
        commit("setLoading", false);
      }
    },

    async deleteSite({ commit }, site: SiteInterface) {
      commit("setLoading", true);
      try {
        if (!site.branch_id) {
          throw new Error("No site ID specified for deletion");
        }

        const axiosResponse: AxiosResponse = await client.delete(
          `/branches/${site.branch_id}`
        );
        if (![200, 204].includes(axiosResponse.status)) {
          throw new Error("Failed to delete site - please try again");
        }

        return true;
      } finally {
        commit("setLoading", false);
      }
    },
  },
};

export default sitesModule;
